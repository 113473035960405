import {connect} from "react-redux";
import React, {Component} from "react";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import {Button} from "semantic-ui-react";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import Lambda from "../controllers/utilities/aws-lambda";
import {formatQuery} from "../controllers/utilities/string-utils";
import {navigate} from "gatsby";
import cookie from "react-cookies";
import {getFullPath} from "../controllers/kaisaku-api";
import Helmet from "react-helmet";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready: false,
            value: "",
            message: "",
            submitted: true
        };
        // rejectIfAnyNull(this.props.session, this.props.transactionId);
    }

    input = (e)=>{
            // console.log(e.target.value);   //方法1
            // console.log(this.txt.value)  //方法3
        this.state.message = "";
        this.state.value = e.target.value;
        if ((this.state.value.length > 0) && (this.state.submitted === true)){
            this.setState({
                submitted: false
            });
        }
    }



    componentWillUnmount() {
        // window.removeEventListener('resize', this.getDirection);
    }

    getDirection = () => {
        this.setState({ state: this.state });
    }

    async componentDidMount() {
        let {transactionId} = formatQuery(this.props.location.search);
        if (transactionId === "showqueryorder"){
            transactionId = cookie.load("_datatranstransactionId");
        }
        if (transactionId) {
            if (this.txt) {
                this.txt.value = "";
            }
        }
        if(this.props.session) {
            const res = await new KaisakuUserApi(this.props.session).getProfile()
            if (res.data){
                const profile = {
                    email: res.data.email,
                    nickname: res.data.nickname,
                    userId: res.data.userId,
                }
                if (profile.email){
                    this.email.value = profile.email;
                }
            }
        }else{
            const accessToken = cookie.load("_accessToken");
            if (accessToken) {
                const res = await Lambda.get(getFullPath("getProfile"), null, accessToken);
                if (res.data){
                    const profile = {
                        email: res.data.email,
                        nickname: res.data.nickname,
                        userId: res.data.userId,
                    }
                    if (profile.email){
                        this.email.value = profile.email;
                    }
                }
            }
        }
        // window.addEventListener('resize', this.getDirection);
    }

    async submitTicket(ticket) {
        if(this.props.session) {
            const res = await new KaisakuUserApi(this.props.session).getProfile()
            if (res.data){
                const profile = {
                    email: res.data.email,
                    nickname: res.data.nickname,
                    userId: res.data.userId,
                }
                Object.assign(ticket, { contact: profile })
            }
        }else{
            const accessToken = cookie.load("_accessToken");
            if (accessToken) {
                const res = await Lambda.get(getFullPath("getProfile"), null, accessToken);
                if (res.data){
                    const profile = {
                        email: res.data.email,
                        nickname: res.data.nickname,
                        userId: res.data.userId,
                    }
                    Object.assign(ticket, { contact: profile })
                }
            }else{
                Object.assign(ticket, { contact: {} })
            }
        }
        // console.log(ticket);
        ticket.toMail = true;
        // console.log(ticket);
        const res = await Lambda.post(`${process.env.cmsEndpoint}/addTicket`, ticket)
        // console.log(res);
        const message = "客服專員收到您的問題及聯繫資料後，會盡快與您聯繫。<br/>感謝您的細心等候。<br/><span style='font-size:0.8rem'>(客服服務時間：每日10:00am~02:00am)</span>";
        this.setState({
            message: message
        });
        return res;
    }

    onContinue() {
        let {pendingTransactionId, newAccessToken, newGameId, newAttributionId} = formatQuery(this.props.location.search);
        if (((newAccessToken === undefined) && (newAccessToken !== null)) || ((newGameId === undefined) && (newGameId !== null))){
            newAccessToken = cookie.load("_accessToken");
            newGameId = cookie.load("_gameId");
            newAttributionId = cookie.load("_attributionId");
            pendingTransactionId = cookie.load("_transactionId");
        }
        if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)){
            let platform = cookie.load("_platform");
            const url = `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
            // console.log(url);
            window.location.href = url;
        }else {
            navigate("/confirm-purchase");
        }
    }

    onReturn(){
        const {transactionId} = formatQuery(this.props.location.search);
        navigate(`/credit-purchase-result/?transactionId=${transactionId}`);
    }

    render() {
        const mode = detectOrient();
        if (this.state.ready === false) {
            if (mode === "LANDSCAPE") {
                if (typeof window !== `undefined`) {
                    navigate(`/payment-support-addticket2/${this.props.location.search}`);
                }
            }else{
                this.state.ready = true;
            }
        }
        return (
            <div style={{
                minHeight: '100vh',
                justifyContent: 'center',
                flexDirection: 'column',
                backgroundColor:"#F8F8F6"
            }}>
                <Helmet>
                    <style>{'body:before { background-image:none; background-color: #F8F8F6; }'}</style>
                </Helmet>
                {(this.state.ready) && (<div style={{width:"100%",height:"100vh", margin:"0 auto"}}>
                        <div style={{padding:"20px", fontSize:"1.3rem", fontWeight:"bold"}}>
                            請留下您的問題及資料，我們將立即與您聯絡
                        </div>
                        <div style={{width:"90vw",margin:"5vw", fontSize:"1.3rem", backgroundColor:"#fff", textAlign:"left", padding:"8px", color:"#888", borderRadius: '0.5rem'}}>
                            <textarea style={{border:"1px solid #ccc", width:"85vw", height:"60vw"}} ref={(node)=>this.txt=node}  onChange={this.input} />
                            <br/>我的聯繫郵箱：<br/>
                            <input type="text"  style={{border:"1px solid #ccc", width:"85vw"}} ref={(node)=>this.email=node}/>
                        </div>
                        <div style={{width:"90vw",margin:"5vw"}}>

                            <Button fluid disabled={this.state.submitted}
                                    onClick={()=>{
                                        if (!validateEmail(this.email.value)){
                                            this.setState({
                                                message: "郵箱地址無效，請重新填寫~"
                                            });
                                            return;
                                        }
                                        this.state.submitted = true;
                                        this.setState({
                                            submitted: true,
                                            message: "正在提交，請稍候~"
                                        });

                                        let message1 = "聯繫郵箱：" + this.email.value + "\n\n";
                                        let {transactionId} = formatQuery(this.props.location.search);
                                        if (transactionId === "showqueryorder"){
                                            transactionId = cookie.load("_datatranstransactionId");
                                        }
                                        if (transactionId) {
                                            message1 += "平台訂單號：" + transactionId + "\n\n";
                                        }
                                        const newGameId = cookie.load("_gameId");
                                        if (newGameId){
                                            message1 += "GameId：" + newGameId + "\n\n";
                                        }
                                        // 獲取cookie數據，如果有，加到message裏，進行發送
                                        const _message = cookie.load("_message");
                                        if (_message){
                                            message1 += _message;
                                        }

                                        this.state.value = message1 + "留言內容：\n\n" + this.state.value;
                                        // console.log(this.state.value);
                                        this.submitTicket({message: this.state.value, topic: "PAYMENT"}).then(this.state.submitted = true);
                                        this.state.value = "";
                                        this.txt.value = "";
                                    }}
                                    style={{marginTop: '1rem'}}>
                                提交
                            </Button>
                        </div>

                        <div style={{width:"90vw",margin:"5vw"}}>
                            <Button fluid
                                    style={{marginTop: '1rem'}}
                                    onClick={this.onReturn.bind(this)}
                            >
                                返回帳單詳情
                            </Button>
                        </div>
                        {(this.state.message) &&
                            (<div style={{width:"90vw",margin:"5vw", fontSize:"1.3rem", backgroundColor:"#fff", textAlign:"left", padding:"8px", color:"#888", borderRadius: '0.5rem'}}
                                  dangerouslySetInnerHTML={{__html:this.state.message}}>
                        </div>)}
                    </div>)}
            </div>
        );
    }


});

function validateEmail(email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

function detectOrient(){
    if(typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    }else{
        return "PORTRAIT";
    }
}